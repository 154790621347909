/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

const React = require('react');
const { RootWrapper } = require('./src/components/RootWrapper');

// Import global styles
require('./src/styles/global.scss');

exports.wrapRootElement = ({ element }) => {
  return <RootWrapper>{element}</RootWrapper>;
};